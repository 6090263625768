/* eslint-disable react/require-default-props */
import * as React from 'react';
import { useState, useEffect, FC } from 'react';

import { useTranslation } from 'react-i18next';
import Button from '../ui/button';
import Video from '../ui/video';
import { ImageGallery } from '../ui/imageGallery';

import pages from '../../utils/pages';

const { getPageUrl } = pages;
const SingleSection: FC<{
  data: any;
  badge?: string;
  badgeClass?: string;
  modal?: boolean;
  children?: any;
}> = ({ data, modal, children }) => {
  const {
    badge,
    badgeClass,
    text,
    title,
    ctaText,
    key,
    media,
    mobileMedia,
    alt,
    video_text,
    video,
  } = data;
  const { t } = useTranslation('general');
  const [videoSrc, setVideo] = useState(null);
  return (
    <section className="section inner_large has-backbround-gradient-grey">
      <div className="container media-box-centered">
        <div className="columns is-centered is-vcentered text-row">
          <div className="column is-10 is-12-mobile has-text-centered no-pad">
            {badge && (
              <span className={`${badgeClass} is-hidden-touch`}>{badge}</span>
            )}
            {title && (
              <h2 className="title title-medium section-title text-black">
                {title}
              </h2>
            )}
            {text && <p className="text-medium">{text}</p>}
            {key && (
              <Button primary to={getPageUrl(key, t('key')).url}>
                {ctaText}
              </Button>
            )}
            {children}
            {/* modal && video_text && (
              <Modal text={video_text} classes="video-modal">
                {video && <Video src={video} name="Shore Video PLayer" />}
              </Modal>
            ) */}
          </div>
        </div>
        <div className="columns is-centered is-vcentered image-row">
          <div className="column is-12 has-text-centered">
            {badge && (
              <span
                className={`${badgeClass} is-visible-mobile is-hidden-desktop`}
              >
                {badge}
              </span>
            )}

            <ImageGallery
              name={media}
              alt={alt}
              className="section-image image-centered is-hidden-touch"
            />
            <ImageGallery
              name={mobileMedia}
              alt={alt}
              className="section-image image-centered is-hidden-desktop"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
SingleSection.defaultProps = {
  modal: false,
  badge: '',
  badgeClass: '',
};

export default SingleSection;
