/* eslint-disable react/require-default-props */
// eslint-disable-next-line react/no-danger
import * as React from 'react';
import { FC, useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Image } from '../../../ui/image';

interface StyledProps {
  block?: string;
  height?: number;
  size?: string;
  className?: any;
  type?: string;
  onKeyDown?: any;
  onClick?: any;
}

const MButton = styled.button<StyledProps>`
  font-family: 'Nunito Sans';
  font-size: 17px;
  text-decoration: underline;
  color: #5860d6;
  text-align: center;
  border: solid 0px rgba(189, 195, 199, 0.6);
  transition: background-color 0.5s;
  background-color: unset;
  path {
    fill: #5860d6;
  }
`;

interface ModalButtonProps {
  children?: any;
  classes?: string;
  toogleModal?: any;
  text?: string;
}

const ModalButton = ({
  children,
  classes,
  text,
  toogleModal,
}: ModalButtonProps) => {
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      toogleModal(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  return (
    <MButton
      className="modal_button"
      onClick={() => toogleModal(true)}
      onKeyDown={() => toogleModal(true)}
      type="button"
    >
      {text}
      <Image
        name="play_circle.svg"
        alt="play_circle icon"
        className="inline-icon play"
      />
    </MButton>
  );
};

interface ModalProps {
  children?: any;
  classes?: string;
  visible: boolean;
  toogleModal?: any;
}
const Modal = ({ children, classes, visible, toogleModal }: ModalProps) => {
  return (
    visible && (
      <div className={`${classes && classes} is-active modal`}>
        <button
          className="close_modal"
          aria-label="close"
          onClick={() => toogleModal(false)}
          onKeyDown={() => toogleModal(false)}
          type="button"
        >
          <Image
            name="close-x.svg"
            alt="play_circle icon"
            className="close-icon close-modal-button"
          />
        </button>
        <div className="modal-content ">{children}</div>
      </div>
    )
  );
};

export { ModalButton, Modal };
