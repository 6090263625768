import * as React from 'react';
import { FC, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalizedPageContext, IColumnList } from '../utils/types';
import { Layout } from '../components/partials/common';
import { SEO } from '../components/seo';
import { HeroPOS } from '../components/partials/common/hero';
import Features from '../components/sections/features';
import SectionFaq from '../components/sections/faq-section';
import SingleSection from '../components/sections/single-section';
import ConversionSection from '../components/sections/conversion';
import { Modal } from '../components/partials/common/elements/Modal';
import Video from '../components/ui/video';
import Shop from '../components/partials/pricing/shop';

const Page: FC<{
  pageContext: LocalizedPageContext;
}> = ({ pageContext }) => {
  const { t } = useTranslation('pos');
  const [modal_visibility, toogleModal] = useState(false);
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      toogleModal(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const { lang, alternateUrls } = pageContext;

  const introData: any = t('intro', {
    returnObjects: true,
  });
  const features: any = t('features', {
    returnObjects: true,
  });
  const questions: IColumnList = t('faq.questions', {
    returnObjects: true,
  });
  return (
    <>
      <Layout
        pageContext={pageContext}
        navClasses="transparent"
        wrapperClasses={` ${modal_visibility && 'modal-active'}`}
        mainClasses={`overlay_nav ${modal_visibility && 'modal-active'}`}
      >
        <SEO
          lang={lang}
          title={t('meta.title')}
          description={t('meta.description')}
          alternateUrls={alternateUrls}
        />
        <HeroPOS translation={t} />
        <SingleSection modal data={introData} />

        <Features data={features} group="group_2" translation={t} />
        <SectionFaq
          classes={t('faq.classes')}
          title={t('faq.title')}
          questions={questions}
        />
        {lang !== 'en' &&
          lang !== 'fr' &&
          lang !== 'en-US' &&
          lang !== 'de-CH' &&
          lang !== 'es' && (
            <Shop
              data={t}
              title="Du möchtest dein Kassenzubehör <br> direkt online bestellen?"
            />
          )}

        <ConversionSection ctaUrl={t('conversion.ctaUrl')} />
      </Layout>
      <Modal
        classes="video-modal"
        toogleModal={toogleModal}
        visible={modal_visibility}
      >
        {introData.video && (
          <Video src={introData.video} name="Shore Video PLayer" />
        )}
      </Modal>
    </>
  );
};

export default Page;
